import React from "react";

import {
  CategoryItem,
  Icon,
  IconWrapper,
  Header,
  Text,
  Title,
  StyledAnchorLink,
  StyledButton,
  StyledGatsbyLink,
  StyledLink,
} from "./styles";

const Container = ({ category, children }) => {
  if (category.slug) {
    return (
      <StyledGatsbyLink to={category.slug} title={category.title}>
        {children}
      </StyledGatsbyLink>
    );
  } else if (category.href) {
    return (
      <StyledLink
        href={category.href}
        title={category.title}
        target="_blank"
        rel="noopener"
      >
        {children}
      </StyledLink>
    );
  }

  return (
    <StyledAnchorLink to={category.anchor} title={category.title}>
      {children}
    </StyledAnchorLink>
  );
};

export const OfferItem = ({ category }) => {
  return (
    <Container category={category}>
      <CategoryItem>
        <Header>
          <IconWrapper>
            <Icon src={category.icon} alt={`ikona ${category.title}`} />
          </IconWrapper>
          <Title>{category.title}</Title>
        </Header>
        <Text>{category.text}</Text>
        <StyledButton>Zobacz więcej</StyledButton>
      </CategoryItem>
    </Container>
  );
};
