import {BigHero}                     from "components/BigHero";
import SEO                           from "components/seo";
import {data_offerServicesNew}       from "data/offerServicesNew";
import {data as testimonialsData}    from "data/testimonials_data";
import React                         from "react";
import {ContactPerson, Testimonials} from "sections/Homepage";
import {OfferList}                   from "sections/Offers/OfferList";
import {useContactPersonData}        from "shared/Hooks/ContactPerson";
import useHerosTypesData             from "shared/Hooks/PageHeaders/useHerosTypesData";
import {OFFER}                       from "shared/Types/heros";

const Oferta = () => {
  const data = useHerosTypesData(OFFER);
  return (
    <>
      <SEO title={data.title} description={data.seo}/>
      <BigHero data={data}/>
      <OfferList data={data_offerServicesNew}/>
      <Testimonials data={testimonialsData}/>
      {/*<ContactPerson personData={useContactPersonData().jakub_furman}/>*/}
    </>
  );
};

export default Oferta;
