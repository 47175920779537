import styled from "styled-components";
import { SectionWrapper } from "shared/Styles/Wrappers";

export const Container = styled.div`
  padding: 124px 0;
  background-color: ${({ theme }) => theme.colors.contact_person};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 72px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: 48px 0;
  }
`;

export const Wrapper = styled(SectionWrapper)``;

export const CategoryBox = styled.div`
  &:not(:last-child) {
    margin-bottom: 96px;
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
      margin-bottom: 64px;
    }
    @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
      margin-bottom: 48px;
    }
  }
`;
export const CategoryTitle = styled.div`
  font-size: 6rem;
  margin-bottom: 32px;
  font-weight: 800;
  color: ${({ theme }) => theme.colors.black};
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 4.2rem;
    line-height: 1.1;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.laptop_small}) {
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_big}) {
    font-size: 3.4rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) and (orientation: portrait) {
    width: 100%;
    text-align: center;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: portrait) {
    font-size: 3rem;
  }

  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet}) and (orientation: landscape) {
    font-size: 2.8rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.phone_big}) and (orientation: landscape) {
    font-size: 2.4rem;
  }
`;
export const CategoryText = styled.div`
  color: ${({ theme }) => theme.colors.black};
  font-size: 1.8rem;
  line-height: 1.67;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.6rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.4rem;
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) and (orientation: portrait) {
    text-align: center;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 1.2rem;
  }
`;

export const CategoryWrapper = styled.div`
  margin-top: 96px;
  display: flex;
  flex-wrap: wrap;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    margin-top: 64px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-top: 48px;
  }
`;
