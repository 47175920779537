// icons
// DESIGN
import WebDesignIcon from "../assets/images/offer-icons/web-design.svg";
import BrandingIcon from "../assets/images/offer-icons/branding.svg";
import IllustrationIcon from "../assets/images/offer-icons/ilustration.svg";
import ModelsIocn from "../assets/images/offer-icons/3d-design.svg";

//MARKETING
import SocialMediaIcon from "../assets/images/offer-icons/social-media.svg";
import GoogleAdsIcon from "../assets/images/offer-icons/kampanie-ads.svg";
import SeoIcon from "../assets/images/offer-icons/seo.svg";
import CopyIcon from "../assets/images/offer-icons/copywriting.svg";

//WEBDEV
import WebpagesIcon from "../assets/images/offer-icons/webpages.svg";
import EcoomerceIcon from "../assets/images/offer-icons/ecommerce.svg";
import AppsIcons from "../assets/images/offer-icons/apps.svg";

//MEDIA
import MediaIcon from "../assets/images/offer-icons/media.svg";

export const data_offerServicesNew = [
  {
    title: "Design",
    id: "web-design",
    text:
      "Zobacz, co możemy stworzyć, aby Twoja marka wyróżniała się na tle innych. Intuicyjne w obsłudze i atrakcyjne strony internetowe, nowoczesny branding, który wyeksponuje Cię na rynku oraz nieszablonowe grafiki i ilustracje - to nasze rozwiązania, które Ci oferujemy.",
    categories: [
      {
        icon: WebDesignIcon,
        title: "Interfejsy stron i aplikacji",
        text:
          "Stworzymy dla Ciebie nie tylko wysoce estetyczny, ale przede wszystkim intuicyjny i przyjazny użytkownikom interfejs strony czy aplikacji.",
        slug: "web-design",
      },
      {
        icon: BrandingIcon,
        title: "Branding",
        text:
          "Opracujemy identyfikację wizualną, która wyeksponuje Twoją markę na rynku i wyróżni Cię na tle konkurencji.",
        slug: "branding",
      },
      {
        icon: IllustrationIcon,
        title: "Ilustracje",
        text:
          "Przygotujemy najwyższej jakości autorskie ilustracje, które uatrakcyjnią i wbogacą komunikację Twojej marki.",
        slug: "ilustracje",
      },
      {
        icon: ModelsIocn,
        title: "Grafiki 2D i 3D",
        text:
          "Zaprojektujemy grafiki 2D i 3D, które atrakcyjnie i przekonywująco przedstawią Twoją ofertę.",
        slug: "grafika-3d",
      },
    ],
  },
  {
    title: "Marketing",
    id: "marketing",
    text:
      "Stworzymy wizerunek Twojej marki, który zapewni Ci widoczność i rozpoznawalność w sieci. Dzięki wykorzystaniu odpowiednich narzędzi dotrzemy do Twoich odbiorców. Przekażemy im wartościowe komunikaty, które uzupełnimy atrakcyjnymi graficznie treściami i niepowtarzalnym tekstem. ",
    categories: [
      {
        icon: SocialMediaIcon,
        title: "Social media",
        text:
          "Zbudujemy Twoją markę w social mediach i społeczność wokół niej, a jej fanów zmienimy w Twoich klientów.",
        slug: "social-media",
      },
      {
        icon: GoogleAdsIcon,
        title: "Google ADS",
        text:
          "Trafimy prosto do osób potencjalnie zainteresowanych Twoją ofertą, pokazując im, że Twoja firma stanowi odpowiedź na ich potrzeby.",
        slug: "kampanie-ads",
      },
      {
        icon: SeoIcon,
        title: "Pozycjonowanie",
        text:
          "Wypozycjonujemy Twoją stronę WWW, zwiększając Twoją widoczność w sieci i ułatwiając dotarcie do Ciebie klientom.",
        slug: "pozycjonowanie-seo",
      },
      {
        icon: CopyIcon,
        title: "Copywriting",
        text:
          "Wzbogacimy komunikację Twojej marki o wartościowe teksty, które przyciągną do Ciebie klientów i lepiej wyeksponują Cię w sieci.",
        slug: "copywriting",
      },
    ],
  },
  {
    title: "Strony i sklepy internetowe",
    id: "web-development",
    text:
      "Zapewniamy szybkie i intuicyjne rozwiązania pozwalające Twojej marce zaistnieć w internecie. Oferujemy usługi programistyczne i graficzne, które dopasują projekt do Twoich oczekiwań i potrzeb. Dzięki wykorzystaniu najnowszych technologii tworzymy profesjonalne strony, sklepy i aplikacje. ",
    categories: [
      {
        icon: WebpagesIcon,
        title: "Strony WWW",
        text:
          "Opracujemy i wdrożymy szybką, wydajną i responsywną stronę internetową, która będzie wizytówką Twojej firmy.",
        slug: "strony-www",
      },
      {
        icon: EcoomerceIcon,
        title: "Sklepy internetowe",
        text:
          "Korzystamy z autorskich oraz dedykowanych rozwiązań e-commerce, które pozwalają Twoim klientom łatwo i szybko dokonać zakupu.",
        slug: "sklepy-internetowe",
      },
      {
        icon: AppsIcons,
        title: "Aplikacje",
        text:
          "Zaprojektujemy intuicyjną i wydajną aplikację mobilną dostosowaną do potrzeb Twojego biznesu.",
        slug: "aplikacje",
      },
    ],
  },
  {
    title: "Media",
    id: "media",
    text:
      "Profesjonalne materiały foto i wideo to drzwi, które otwierają nowe możliwości w marketingu. Opracujemy również działania, które zaprezentują Twoją markę w mediach tradycyjnych. Przygotujemy reklamę zewnętrzną, abyś był zauważalny w każdym miejscu. ",
    categories: [
      {
        icon: MediaIcon,
        title: "Foto & Video & Animacje",
        text:
          "Wykonamy zdjęcia, filmy i animacje, które przyciągną uwagę odbiorców i wzbogacą każdy kanał komunikacji Twojej marki.",
        slug: "media",
      },
    ],
  },
];
