import React from "react";
import uuid from "react-uuid";
import { OfferItem } from "components/OfferItem";
import {
  Container,
  Wrapper,
  CategoryBox,
  CategoryTitle,
  CategoryText,
  CategoryWrapper,
} from "./styles";

export const OfferList = ({ data }) => {
  return (
    <Container>
      <Wrapper>
        {data.map((item) => (
          <CategoryBox key={uuid()} id={item.id}>
            <CategoryTitle>{item.title}</CategoryTitle>
            <CategoryText>{item.text}</CategoryText>
            <CategoryWrapper>
              {item.categories.map((category) => (
                <OfferItem category={category} key={uuid()} />
              ))}
            </CategoryWrapper>
          </CategoryBox>
        ))}
      </Wrapper>
    </Container>
  );
};
