import styled, { css } from "styled-components";
import { Button } from "components/Buttons";
import { Paragraph } from "components/Typography";
import { Link } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const defaultLink = css`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 48px 40px;
  width: calc(50% - 32px);
  margin-bottom: 36px;
  box-shadow: 0px 0px 4px -4px ${({ theme }) => theme.colors.box_shadow};
  transition: 0.2s box-shadow ease;
  &:hover {
    box-shadow: 0px 0px 24px -8px ${({ theme }) => theme.colors.box_shadow};
  }
  &:not(:nth-child(2n)) {
    margin-right: 64px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    padding: 36px 24px;
    width: calc(50% - 18px);
    &:not(:nth-child(2n)) {
      margin-right: 36px;
    }
  }
  @media screen and (max-width: ${({ theme }) =>
      theme.breakpoints.tablet_small}) {
    padding: 24px 24px;
    width: 100%;
    margin-right: 0 !important;
    margin-bottom: 24px;
  }
`;

export const StyledAnchorLink = styled(AnchorLink)`
  ${defaultLink}
`;

export const StyledLink = styled.a`
  ${defaultLink}
`;

export const StyledGatsbyLink = styled(Link)`
  ${defaultLink}
`;

export const CategoryItem = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
`;

export const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    margin-right: 12px;
  }
`;

export const Icon = styled.img`
  width: 46px;
  height: 46px;
  object-fit: contain;
  object-position: center;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 36px;
    height: 36px;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 24px;
    height: 24px;
  }
`;

export const Title = styled.h3`
  font-size: 3rem;
  color: ${({ theme }) => theme.colors.black};

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 2.4rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 2rem;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.phone}) {
    font-size: 1.6rem;
  }
`;

export const Text = styled(Paragraph)`
  font-size: 1.6rem;
  line-height: 1.67;
  margin: 36px 0;
  max-width: 500px;
  width: 100%;
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.laptop}) {
    font-size: 1.4rem;
    margin: 24px 0;
  }
  @media screen and (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    font-size: 1.2rem;
    max-width: 400px;
  }
`;

export const StyledButton = styled(Button)``;
